import React from "react";
import Layout from "./components/ui/Layout";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

// import { HashRouter as Router } from "react-router-dom";

function App() {
  // console.log("APP");
  // <Routes>
  //     <Route path="products/*" element={<ProductsRoute />} />
  //     <Route path="users/*" element={<UsersRoute />} />
  //   </Routes>
  return (
    <div>
      <Router>
        <Layout />
      </Router>
    </div>
  );
}

export default App;
