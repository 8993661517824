import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Radio,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import carApi from "api/carApi";
import moment from "moment";
import "moment/locale/th";
import { Link, useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import { Edit } from "@mui/icons-material";
import AddUserModal from "./AddUserModal";

export default function UserList() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [dataHolder, setDataHolder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  useEffect(() => {
    const fecthUser = async () => {
      setLoading(true);
      await carApi
        .get("salecar/admin/users", {
          headers: {
            "x-access-token": user.token,
          },
        })
        .then((response) => {
          const data = response.data;
          // setDataHolder(data);
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          dispatch(addToken({}));
          navigate("/login");
          Modal.warning({
            title: "คำขอล้มเหลว",
            content: "กรุณาเข้าสู่ระบบ",
            centered: true,
          });
        });

      setLoading(false);
    };

    fecthUser();
  }, []);

  const handleClickAddUser = () => {
    setOpenAddUserModal(true);
  };

  const handleSubmitAddUser = (data) => {
    signup(data);
  };

  useEffect(() => {
    console.log("DATA", data);
  }, [data]);

  const signup = async (data) => {
    await carApi
      .post("/salecar/signup", data)
      .then((response) => {
        if (response.status === 201) {
          Modal.success({
            title: "สำเร็จ",
            content: "เพิ่มข้อมูลผู้ใช้งานสำเร็จ",
            centered: true,
          });
          // console.log(response.data);
          let date = moment();
          let status = "";
          if (data.status_id === 1) {
            status = "อนุมัติ";
          } else status = "ไม่อนุมัติ";
          setData((pre) => [
            { id: response.data.id, date, status, ...data },
            ...pre,
          ]);
        } else {
          Modal.warning({
            title: "ผิดพลาดบางประการ",
            content: "กรุณาตรวจสอบข้อมูลให้ถูกต้อง",
            centered: true,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          content: error.message,
          centered: true,
        });
      });
  };

  const columns = [
    {
      key: 1,
      dataIndex: "date",
      title: "วันที่สมัคร",
      render: (date) => {
        return moment(date).format("LL");
      },
    },
    {
      key: 2,
      //   dataIndex: "name",
      title: "รายละเอียดสมาชิก",
      render: (rec) => {
        return (
          <>
            <Typography style={{ fontWeight: "bold" }}>
              {rec.firstname + " " + rec.lastname}
            </Typography>

            <Typography style={{ color: "gray" }}>
              วันเกิด : {moment(rec.birthdate).format("ll")} (
              {moment().year() - moment(rec.birthdate).year()} ปี)
            </Typography>
            {/* <Typography style={{ color: "gray" }}>
              ที่อยู่ : {rec.address}
            </Typography> */}
            <Typography style={{ color: "gray" }}>
              โทรศัพท์ : {rec.phone}
            </Typography>
          </>
        );
      },
    },

    {
      key: 3,
      // dataIndex: "address",
      title: "เต็นท์รถ",
      render: (rec) => {
        if (rec.car_tent_name) {
          return (
            <>
              {/* <div
                style={{ backgroundColor: "gray", height: 0.3, marginTop: 4 }}
              ></div> */}
              <Typography style={{ fontWeight: "bold" }}>
                {rec.car_tent_name}
              </Typography>
              {/* <Typography style={{ color: "gray" }}>
                ที่อยู่ : {rec.car_tent_address}
              </Typography> */}
              <Typography style={{ color: "gray" }}>
                โทรศัพท์ {rec.car_tent_phone}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                ความสนใจพิเศษ : {rec.interest || "-"}
              </Typography>
            </>
          );
        }
        return <Typography>ไม่มีข้อมูลเต็นท์รถ</Typography>;
      },
    },

    {
      key: 4,
      //   dataIndex: "status",
      title: "สถานะ",
      render: (rec) => {
        let color;
        switch (rec.status_id) {
          case 1:
            color = "success";

            break;
          case 2:
            color = "processing";

            break;
          case 3:
            color = "error";

            break;

          default:
            break;
        }

        return <Tag color={color}>{rec.status}</Tag>;
      },
    },
    {
      key: 5,
      dataIndex: "mark",
      title: "หมายเหตุ",
    },
    {
      key: 6,
      // dataIndex: ""
      title: "",
      render: function (rec) {
        return <Link to={"/users/" + rec.id}>รายละเอียดสมาชิก</Link>;
      },
    },
  ];

  // const handleSearchTypeStatus = (e) => {
  //   const term = e.target.value;
  //   if (term === 0) {
  //     setData(dataHolder);
  //   } else {
  //     const newData = dataHolder.filter((item) => {
  //       return item.status_type_id === term;
  //     });
  //     setData(newData);
  //   }
  // };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h3>ผู้ใช้งานทั้งหมด</h3>
      <div
        style={{
          padding: 8,
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={handleClickAddUser}
        >
          เพิ่มผู้ใช้งาน
        </Button>
      </div>
      <Table dataSource={data} columns={columns} />
      <AddUserModal
        open={openAddUserModal}
        onClose={setOpenAddUserModal}
        onSubmit={handleSubmitAddUser}
      />
    </div>
  );
}
