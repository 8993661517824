import React, { useState, useEffect } from "react";
import { Select } from "antd";
import carApi from "api/carApi";

export default function SelectBrand({ typeId, onChange, value }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [typeId]);

  const handleChange = (e, v) => {
    // console.log(e, v);
    onChange(v);
  };

  const fetchData = async function () {
    const result = await carApi.get("/ratecars/brand/" + typeId);
    setData(result.data);
  };
  return (
    <Select value={value} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option
            key={item.idbrand}
            value={item.idbrand}
            name="brand_id"
          >
            {item.brand}
          </Select.Option>
        );
      })}
    </Select>
  );
}
