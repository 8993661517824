import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Divider,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { Card, Image, Modal, Statistic } from "antd";

import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

import carApi from "api/carApi";
import { currencyFormat } from "components/utils/Utils";
import moment from "moment/moment";
import "moment/locale/th";
import Loading from "components/Loading";
// import AuctionCarModal from "./AuctionCarModal";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import CalculateLoan from "./CalculateLoan";

const { Countdown } = Statistic;

export default function CarDetail() {
  const user = useSelector((state) => state.token);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productDetail, setProductDetail] = useState({});
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setLoading] = useState(false);
  const [imageShow, setImageShow] = useState("");
  const [imageList, setImageList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  // const [dueDateTime, setDueDateTime] = useState("");
  // const [checKAuctionStatus, setAuctionStatus] = useState(1);
  // const [auctionAvaliable, setAuctionAvaliable] = useState(false);
  // const [colorCooldown, setColorCooldown] = useState("green");
  // const [users, setUsers] = useState([]);

  useEffect(() => {
    loadProductSubDetail();
    // getUserList();
  }, [id]);

  // const getUserList = async () => {
  //   setLoading(true);
  //   await carApi
  //     .get("/salecar/admin/users", {
  //       headers: {
  //         "x-access-token": user.token,
  //       },
  //     })
  //     .then((response) => {
  //       // console.log(response.data);
  //       setUsers(response.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  const loadProductSubDetail = async () => {
    setLoading(true);
    await carApi
      .get(`/salecar/products/${id}`)
      .then((response) => {
        setProductDetail(response.data);
        console.log(response.data);
        // setImageList([{ url: response.data.image_path }]);
        if (response.data.product_code) {
          loadImagesProduct(response.data.product_code);
          setImageShow(response.data.image_path);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadImagesProduct = async (product_code) => {
    setLoading(true);
    await carApi
      .get("/salecar_files/list/" + product_code)
      .then((response) => {
        // console.log(response.data);
        setImageList(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateAuctionData = async (data) => {
    setLoading(true);
    await carApi
      .put(`/salecar/auction-price/products/${data.id}`, data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // setLoading(false);
        // onClose(false);
        Modal.success({
          content: "อัพเดตรายการประมูลสำเร็จ",
          centered: true,
        });
        loadProductSubDetail();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoading(false);
          dispatch(addToken({}));
          navigate("/login");
          Modal.error({
            title: "ไม่พบบัญชีผู้ใช้",
            content: "บัญชีผู้ใช้งานของคุณถูกระงับ กรุณาติดต่อเจ้าหน้าที่",
            centered: true,
          });
        } else {
          console.error(error);
          setLoading(false);
          dispatch(addToken({}));
          navigate("/login");
          Modal.warning({
            title: "คำขอล้มเหลว",
            content: "กรุณาเข้าสู่ระบบ",
            centered: true,
          });
        }
      });
  };

  const handleClickAuction = async () => {
    setOpenModal(true);
  };

  const handleSubmit = (e) => {
    updateAuctionData(e);
  };

  // useEffect(() => {
  //   const currentDateTime = new Date();
  //   const deadLine = Date.parse(productDetail.auction_due_datetime);
  //   const miliseconds = deadLine - currentDateTime;
  //   // console.log(miliseconds);
  //   if (deadLine > currentDateTime) {
  //     setAuctionAvaliable(true);
  //     setAuctionStatus(1);
  //     if (miliseconds <= 6000000) {
  //       setColorCooldown("red");
  //     } else setColorCooldown("green");
  //   } else if (isNaN(deadLine) || deadLine === null) {
  //     setAuctionStatus(0);
  //     setAuctionAvaliable(false);
  //   } else {
  //     setAuctionAvaliable(false);
  //     setAuctionStatus(2);
  //   }

  //   setDueDateTime(deadLine);
  // }, [productDetail]);

  const rederDetail = (title, description) => {
    return (
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={8}
        >
          <p style={{ color: "gray" }}>{title}</p>
          <p>{description}</p>
        </Stack>
        <Divider />
      </div>
    );
  };

  const handleClickCalculateLoan = () => {
    setOpenModal(true);
  };

  const handleSubmitCalculate = (data) => {
    console.log("SUBMIT", data);
  };

  const censorName = (text) => {
    // console.log(text);
    let name = text.substring(0, 3);
    for (let i = 3; i < text.length; i++) {
      name += "x";
    }
    return name;
  };

  if (isLoading) return <Loading />;

  return (
    <div>
      <Card style={{ padding: 24 }}>
        <Grid
          container
          spacing={8}
          justifyContent="center"
          justify={isMediumUp ? "flex-start" : "center"}
        >
          <Grid item>
            <Grid
              container
              //   className={classes.content}
              direction="column"
              // sm={6}
              justify="space-between"
            >
              <Grid item>
                <Image
                  // preview={false}
                  width={isMediumUp ? 700 : 350}
                  src={imageShow}
                />
              </Grid>
              <Grid item>
                <div style={{ width: isMediumUp ? 700 : 350 }}>
                  <ImageList
                    rowHeight={100}
                    spacing={2}
                    // sx={{ overflowX: "auto" }}
                  >
                    <ImageListItem
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      {imageList.map((item) => (
                        <Image
                          key={item.name}
                          preview={false}
                          src={item.url}
                          width={100}
                          onClick={() => setImageShow(item.url)}
                        />
                      ))}
                    </ImageListItem>
                  </ImageList>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              //   className={classes.content}
              direction="column"
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h6" component="h1">
                  {productDetail.brand} {productDetail.model} (
                  {productDetail.year})
                </Typography>
                <Typography>{productDetail.sub_model}</Typography>
                <Typography>
                  {currencyFormat(productDetail.miles)} กม. |{" "}
                  {productDetail.gear_type}
                </Typography>
                <p>หมายเหตุ :{productDetail.mark}</p>

                <div>
                  <h2 style={{ color: "green" }}>
                    ราคาขาย {currencyFormat(productDetail.retail_price)} บาท
                  </h2>
                  <h3 style={{ color: "green" }}>
                    เงินดาวน์ {currencyFormat(productDetail.retail_down_price)}{" "}
                    บาท
                  </h3>
                  <h4 style={{ color: "red" }}>
                    ข้อเสนอพิเศษ : {productDetail.promotion}
                  </h4>
                </div>
                <Button variant="contained" onClick={handleClickCalculateLoan}>
                  คำนวณยอดผ่อน
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {/* <Divider /> */}
      <h4 style={{ justifyContent: "center", display: "flex" }}>
        รายละเอียดรถยนต์
      </h4>
      <Card style={{ padding: 24 }}>
        <Grid
          container
          spacing={isMediumUp ? 8 : 0}
          direction="row"
          justifyContent="center"
          justify={isMediumUp ? "flex-start" : "center"}
        >
          <Grid item>
            {rederDetail("ประเภทเชื้อเพลิง", productDetail.fuel_type)}
            {rederDetail("จำนวนที่นั่ง", productDetail.seats)}
            {rederDetail("ประเภทการจดทะเบียน", productDetail.registration_type)}
            {rederDetail(
              "กุญแจสำรอง",
              productDetail.spare_key ? "มี" : "ไม่มี"
            )}
            {rederDetail(
              "การรับประกันหลัก",
              productDetail.maim_warranty ? "มี" : "ไม่มี"
            )}
          </Grid>
          <Grid item>
            {rederDetail("สี", productDetail.color)}
            {rederDetail(
              "วันจดทะเบียน",
              moment(productDetail.registration_date).format("LL")
            )}
            {rederDetail(
              "ระยะทางล่าสุด",
              currencyFormat(productDetail.miles) + " กม."
            )}
            {rederDetail(
              "สมุดคู่มือการเข้าศูนย์บริการ",
              productDetail.service_book_center ? "มี" : "ไม่มี"
            )}
            {rederDetail(
              "วันหมดอายุภาษีรถยนต์",
              moment(productDetail.tax_expiration_date).format("LL")
            )}
          </Grid>
        </Grid>
      </Card>
      {/* <AuctionCarModal
        open={openModal}
        users={users}
        data={productDetail}
        onSubmit={handleSubmit}
        onClose={setOpenModal}
      /> */}
      <CalculateLoan
        open={openModal}
        data={productDetail}
        onClose={setOpenModal}
        onSubmit={handleSubmitCalculate}
      />
    </div>
  );
}
