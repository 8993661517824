import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  DirectionsCar as DirectionsCarIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Sell as SellIcon,
  Info as InfoIcon,
  Call as CallIcon,
  Logout as LogoutIcon,
  ContactSupport as ContactSupportIcon,
  AccountCircle as AccountCircleIcon,
  LocalMall as LocalMallIcon,
  PeopleAlt as PeopleAltIcon,
  Bookmark as BookmarkIcon,
  ShopOutlined as ShopOutlinedIcon,
  Store as StoreIcon,
  Assessment as AssessmentIcon,
} from "@mui/icons-material";
import { ClickAwayListener } from "@mui/base";

import logo from "assets/images/KSM-White-02.png";
import { COLOR } from "theme";
import { Modal, Space } from "antd";
import { addToken } from "components/store/tokenSlice";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  // color: "gray",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: "flex-end",
}));

export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenuItem = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickMenuItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleSelectedMenuItem = (event) => {
    console.log(event.target.value);
    switch (event.target.value) {
      case 1:
        navigate("/users/" + user.id);
        break;
      case 2:
        navigate("/users/auction-list/" + user.id);
        break;
      case 3:
        Modal.confirm({
          title: "ออกจากระบบ",
          content: "คุณต้องการออกจากระบบหรือไม่?",
          centered: true,
          onOk() {
            dispatch(addToken(null));
            navigate("/login");
          },
        });

        break;
      default:
        break;
    }
  };

  const menuAdminList = [
    { name: "ประมูลรถ", icon: <DirectionsCarIcon />, route: "products" },
    {
      name: "ขายปลีก",
      icon: <ShopOutlinedIcon />,
      route: "retails",
    },
    {
      name: "เพิ่มรายการสินค้า",
      icon: <SellIcon />,
      route: "products/admin/add",
    },
    {
      name: "รายการประมูล",
      icon: <LocalMallIcon />,
      route: "products/admin/list",
    },
    {
      name: "รายการปิดประมูล",
      icon: <BookmarkIcon />,
      route: "products/admin/actions-completed-list",
    },
    {
      name: "รายการขายปลีก",
      icon: <StoreIcon />,
      route: "retails/admin/list",
    },
    {
      name: "สรุปยอดขาย",
      icon: <AssessmentIcon />,
      route: "reports/admin",
    },
    {
      name: "ผู้ใช้งานทั้งหมด",
      icon: <PeopleAltIcon />,
      route: "users/admin/list",
    },
    { name: "เกี่ยวกับ", icon: <InfoIcon /> },
    { name: "คำถามที่พบบ่อย", icon: <ContactSupportIcon /> },
  ];

  const menuUerList = [
    { name: "ประมูลรถ", icon: <DirectionsCarIcon />, route: "products" },
    { name: "เกี่ยวกับ", icon: <InfoIcon /> },
    { name: "คำถามที่พบบ่อย", icon: <ContactSupportIcon /> },
  ];

  const menuSaleList = [
    {
      name: "ขายปลีก",
      icon: <ShopOutlinedIcon />,
      route: "retails",
    },
    { name: "เกี่ยวกับ", icon: <InfoIcon /> },
    { name: "คำถามที่พบบ่อย", icon: <ContactSupportIcon /> },
  ];

  const menuList2 = [
    { name: "043-239-888", icon: <CallIcon /> },
    // { name: "ออกจากระบบ", icon: <LogoutIcon /> },
  ];

  const renderItem = ({ data, index }) => {
    // console.log(data);
    return (
      <ListItem
        button
        key={index}
        disablePadding
        to={data.route}
        component={RouterLink}
      >
        <ListItemButton>
          <ListItemIcon>{data.icon}</ListItemIcon>
          <ListItemText primary={data.name} />
        </ListItemButton>
      </ListItem>
    );
  };

  const logoShow = () => {
    let path = "";
    if (user.role === "admin" || user.role === "user") {
      path = "/products";
    } else {
      path = "/retails";
    }
    return (
      <Link to={path} color="inherit" component={RouterLink} underline="none">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <img
            src={logo}
            width={45}
            alt="ONE Logo"
            style={{ marginRight: 16 }}
          /> */}
          <Typography variant="h6" noWrap component="div">
            KSM USED CAR
          </Typography>
        </div>
      </Link>
    );
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => open && setOpen(false)}
    >
      <Box sx={{ display: "flex" }}>
        <AppBar
          style={{ backgroundColor: COLOR.primary }}
          position="fixed"
          open={open}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            {!open ? logoShow() : null}
            <div style={{ flexGrow: 1 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!user.token ? (
                <Space>
                  <Link
                    style={{ fontSize: 14 }}
                    underline="none"
                    color="white"
                    to="/login"
                    component={RouterLink}
                  >
                    เข้าสู่ระบบ
                  </Link>
                  <Link
                    style={{ fontSize: 14 }}
                    underline="none"
                    color="white"
                    to="/sign-up"
                    component={RouterLink}
                  >
                    สมัครสมาชิก
                  </Link>
                </Space>
              ) : (
                <div>
                  <IconButton
                    color="inherit"
                    id="basic-button"
                    aria-controls={openMenuItem ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenuItem ? "true" : undefined}
                    onClick={handleClickMenuItem}
                  >
                    <AccountCircleIcon fontSize="large" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenuItem}
                    onClick={handleSelectedMenuItem}
                    onClose={handleCloseMenuItem}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value={1} onClick={handleCloseMenuItem}>
                      โปรไฟล์ของฉัน
                    </MenuItem>
                    {user.role === "user" && (
                      <MenuItem value={2} onClick={handleCloseMenuItem}>
                        รายการประมูลของฉัน
                      </MenuItem>
                    )}

                    <MenuItem value={3} onClick={handleCloseMenuItem}>
                      ออกจากระบบ
                    </MenuItem>
                  </Menu>
                </div>
                // <IconButton color="inherit" onClick={() => {}}>
                //   <AccountCircleIcon fontSize="large" />

                // </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            {logoShow()}
            <div style={{ flexGrow: 1 }}></div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List>
            {user.role === "admin"
              ? menuAdminList.map((data, index) => renderItem({ data, index }))
              : user.role === "user"
              ? menuUerList.map((data, index) => renderItem({ data, index }))
              : menuSaleList.map((data, index) => renderItem({ data, index }))}
          </List>
          <Divider />
          <List>
            {menuList2.map((data, index) => renderItem({ data, index }))}
          </List>
        </Drawer>
      </Box>
    </ClickAwayListener>
  );
}
