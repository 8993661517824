import { createSlice, current } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: {},
  },
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload;
      // console.log("STATE", current(state));
    },
  },
});

export const { addToken } = tokenSlice.actions;
export default tokenSlice.reducer;
