import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Divider,
  Spin,
  Modal,
} from "antd";
import { useTheme } from "@mui/material/styles";
import { InboxOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { Container, useMediaQuery } from "@mui/material";
import carApi from "api/carApi";
import axios from "axios";
import moment from "moment";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
// const { Header, Footer, Content } = Layout;

export default function SignUp() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [radioCheck, setRadioCheck] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // const [profile, setProfile] = useState({});
  // const [userData, setUserData] = useState({});
  const [idcardData, setIdcardData] = useState(null);
  // const [fileList, setFileList] = useState([]);
  const [vehicleTypeSelected, setVehicleTypeSelected] = useState(null);
  const [loadingIdcard, setLoadingIdcard] = useState(false);

  useEffect(() => {
    fetchVehicleTypes();
  }, []);

  // useEffect(() => {
  //   console.log("USER", userData);
  // }, [userData]);

  const fetchVehicleTypes = async () => {
    setLoadingIdcard(true);
    const { data } = await carApi.get(`/salecar/product-types`);
    // console.log(data);
    setVehicleTypes(data);
    setLoadingIdcard(false);
  };

  const toBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      fetchUserDataFromIdcard(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const censorName = (text) => {
    // console.log(text);
    let name = text.substring(0, 6);
    for (let i = 6; i < text.length; i++) {
      name += "x";
    }
    return name;
  };

  const fetchUserDataFromIdcard = async (base64) => {
    setLoadingIdcard(true);
    const { data } = await axios.post(
      `https://test-i67f6gaaqa-as.a.run.app/upload`,
      { image: base64 }
    );
    // console.log(data[0]);
    checkUserExists(data[0]);
    // setIdcardData(data[0]);
    // setLoadingIdcard(false);
  };

  const checkUserExists = async (user) => {
    // console.log(user);
    setLoadingIdcard(true);
    await carApi
      .get(`/salecar/users/idcard/` + user.identificationId)
      .then((res) => {
        // console.log(res);
        if (res.data) {
          Modal.warning({
            title: "มีบัญชีผู้ใช้งานนี้แล้ว",
            content:
              "เลขบัตรประชาชนนี้ ลงทะเบียนไว้กับหมายเลข " +
              censorName(res.data.phone),
            centered: true,
          });
          setLoadingIdcard(false);
        } else {
          setIdcardData(user);
          setLoadingIdcard(false);
        }
        // console.log(res.data);

        // alert(
        //   "ขออถัย บัตรประชาชนนี้เคยเลงทะเบียนแล้ว ด้วยหมายเลข " +
        //     censorName(res.data.phone)
        // );

        // else if (res.status === 201) {
        //   Modal.success({
        //     title: "ลงทะเบียนแล้ว",
        //     content:
        //       "กรุณารอผลพิจารณาทาง SMS ใน 1-2 วันทำการ " +
        //       censorName(res.data.phone),
        //     centered: true,
        //   });

        //   setLoadingIdcard(false);
        // }
        // else {
        //   setIdcardData(user);
        //   setLoadingIdcard(false);
        // }
      })
      .catch((err) => {
        Modal.error({ content: err.message });
        setLoadingIdcard(false);
      });
  };

  // const handleUploadCover = ({ idcard }) => {
  //   console.log(profile);
  //   const formData = new FormData();
  //   formData.append("file", profile);
  //   setLoading(true);
  //   // console.log(productData);
  //   // You can use any AJAX library you like
  //   carApi
  //     .post("/salecar_files/upload/idcard/" + idcard, formData, {
  //       headers: {
  //         "content-type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => {
  //       // setUploadImageCover(true);
  //       // setProductData({ ...productData, image_path: res.data });
  //       // alert("upload successfully.");
  //     })
  //     .catch((err) => {
  //       alert("อัพโหลดรูปภาพไม่สำเร็จ");
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const onFinish = (values) => {
    // console.log("Success:", values);
    let userData;
    if (radioCheck) {
      userData = {
        ...values,
        interest_car_type_id: vehicleTypeSelected,
        status_id: 2,
        // role: "user",
      };
    } else {
      userData = {
        ...values,
        interest_car_type_id: vehicleTypeSelected,
        car_tent_name: null,
        car_tent_address: null,
        car_tent_phone: null,
        car_tent_taxid: null,
        status_id: 2,
        // role: "user",
      };
    }

    signup(userData);

    // console.log("USER", userData);
  };

  const signup = async (userData) => {
    await carApi
      .post("/salecar/signup", userData)
      .then((response) => {
        if (response.status === 201) {
          Modal.success({
            title: "ลงทะเบียนสำเร็จ",
            content: "กรุณารอผลพิจารณาทาง SMS ภายใน 1-2 วันทำการ",
            centered: true,
          });
          navigate("/products");
        } else {
          Modal.warning({
            title: "ผิดพลาดบางประการ",
            content: "กรุณาตรวจสอบข้อมูลให้ถูกต้อง",
            centered: true,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          content: error.message,
          centered: true,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onRadioChange = (e) => {
    setRadioCheck(e.target.value);
  };

  const handleVehicleTypeChange = (v, e) => {
    console.log(e);
    if (e !== 0) {
      setVehicleTypeSelected(e.label);
    } else setVehicleTypeSelected(null);
  };

  const propsIdcardFile = {
    beforeUpload: (file) => {
      toBase64(file);

      return false;
    },
  };
  // const propsMultifile = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);
  //     return false;
  //   },
  // };

  const CustomTextField = ({ text }) => {
    return (
      <div
        style={{
          border: "1px solid rgba(128,128,128,0.3)",
          borderRadius: "6px",
          padding: 4,
        }}
      >
        <Typography style={{ marginLeft: 4, color: "gray" }}>{text}</Typography>
      </div>
    );
  };
  return (
    <>
      <Container className="layout-default ant-layout layout-sign-up">
        <div className="sign-up-header">
          <div className="content">
            <Title>สมัครสมาชิก</Title>
            <p className="text-lg">
              สมัครสมาชิกเพื่อร่วมประมูลรถมือสองในเว็บของเรา
            </p>
          </div>
        </div>

        <Card
          title={<h4> กรุณากรอกให้ครบทุกช่องเพื่อการสมัครสมาชิกที่ง่ายขึ้น</h4>}
          bordered="false"
        >
          <div
            style={{
              // backgroundColor: "yellow",
              padding: 24,
              // justifySelf: "center",
              justifyContent: "center",
              // alignItems: "center",
              display: "flex",
            }}
          >
            <Upload.Dragger
              style={{ padding: 24 }}
              maxCount={1}
              {...propsIdcardFile}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                คลิก หรือลากวาง รูปถ่ายบัตรประชาชน
              </p>
              <p className="ant-upload-hint">
                เพื่อยืนยันตัวตันของสมาชิกแต่ละท่าน
              </p>
            </Upload.Dragger>
          </div>
          <Divider />
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="row-col"
          >
            <Row gutter={24}>
              {idcardData ? (
                <>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      initialValue={idcardData.firstname}
                      name="firstname"
                      label="ชื่อ"
                      rules={[{ required: true, message: "กรุณากรอกชื่อ!" }]}
                    >
                      <CustomTextField text={idcardData.firstname} />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      initialValue={idcardData.lastname}
                      label="นามสกุล"
                      name="lastname"
                      rules={[{ required: true, message: "กรุณากรอกนามสกุล!" }]}
                    >
                      <CustomTextField text={idcardData.lastname} />
                    </Form.Item>
                  </Col>

                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      initialValue={idcardData.identificationId}
                      label="เลขประจำตัวประชาชน"
                      name="idcard"
                      rules={[
                        {
                          required: true,
                          message:
                            "กรุณากรอกเลขบัตร เพื่อใช้ในการตรวจสอบเครดิต!",
                        },
                      ]}
                    >
                      <CustomTextField text={idcardData.identificationId} />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      initialValue={idcardData.birthdate}
                      label="วัน-เดือน-ปี เกิด"
                      name="birthdate"
                      // rules={[{ required: true, message: "กรุณากรอกนามสกุล!" }]}
                    >
                      <CustomTextField
                        text={moment(idcardData.birthdate)
                          .add(543, "years")
                          .format("LL")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      initialValue={idcardData.address}
                      label="ที่อยู่"
                      name="address"
                      rules={[{ required: true, message: "กรุณากรอกที่อยู่!" }]}
                    >
                      <CustomTextField text={idcardData.address} />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      label="รหัสไปรษณ์"
                      name="zipcode"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอกรหัสไปรษณ์!",
                        },
                      ]}
                    >
                      <Input placeholder="รหัสไปรษณ์" />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      label="เบอร์โทร"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอกเบอร์โทรที่ติดต่อได้!",
                        },
                      ]}
                    >
                      <Input placeholder="เบอร์โทร" />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item label="ชื่อเล่น (ถ้ามี)" name="nickname">
                      <Input placeholder="ชื่อเล่น" />
                    </Form.Item>
                  </Col>
                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item label="อีเมล์ (ถ้ามี)" name="email">
                      <Input placeholder="อีเมล์" />
                    </Form.Item>
                  </Col>

                  <Col span={isMediumUp ? 12 : 24}>
                    <Form.Item
                      label="ความสนใจพิเศษ"
                      name="interest_car_type_id"
                    >
                      <Select
                        defaultValue="ทั้งหมด"
                        style={{
                          width: "100%",
                        }}
                        onChange={(v, e) => handleVehicleTypeChange(v, e)}
                      >
                        <Select.Option key={0} value="ทั้งหมด" label={0} />
                        {vehicleTypes.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              value={el.type_th}
                              label={el.id}
                            />
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="ท่านทำธุรกิจเต็นท์รถมือสองหรือไม่?"
                      name="cartent"
                    >
                      <Radio.Group
                        onChange={onRadioChange}
                        defaultValue={false}
                      >
                        <Radio value={false}>ไม่ใช่</Radio>
                        <Radio value={true}>ใช่</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {radioCheck ? (
                    <>
                      <Col span={isMediumUp ? 12 : 24}>
                        <Form.Item
                          label="ชื่อเต็นท์รถ"
                          name="car_tent_name"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกชื่อเต็นท์รถ!",
                            },
                          ]}
                        >
                          <Input placeholder="ชื่อเต็นท์รถ" />
                        </Form.Item>
                      </Col>
                      <Col span={isMediumUp ? 12 : 24}>
                        <Form.Item
                          label="เลขประจำตัวผู้เสียภาษี"
                          name="car_tent_taxid"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกเลขผู้เสียภาษี!",
                            },
                          ]}
                        >
                          <Input placeholder="เลขประจำตัวผู้เสียภาษี" />
                        </Form.Item>
                      </Col>
                      <Col span={isMediumUp ? 12 : 24}>
                        <Form.Item
                          label="ที่อยู่เต็นท์รถ"
                          name="car_tent_address"
                          rules={[
                            { required: true, message: "กรุณากรอกที่อยู่!" },
                          ]}
                        >
                          <Input placeholder="ที่อยู่เต็นท์รถ" />
                        </Form.Item>
                      </Col>
                      <Col span={isMediumUp ? 12 : 24}>
                        <Form.Item
                          label="เบอร์สำนักงาน"
                          name="car_tent_phone"
                          rules={[
                            { required: true, message: "กรุณากรอกเบอร์โทร!" },
                          ]}
                        >
                          <Input placeholder="เบอร์สำนักงาน" />
                        </Form.Item>
                      </Col>
                      {/* <Col span={isMediumUp ? 12 : 24}>
                        <Form.Item
                          label="รูปเต็นท์รถ"
                          name="all_image"
                          rules={[
                            { required: true, message: "ต้องไม่เว้นว่าง!" },
                          ]}
                          // valuePropName="fileList"
                          // getValueFromEvent={normFile}
                        >
                          <Upload
                            {...propsMultifile}
                            // directory
                            //  action="/upload.do"
                            listType="picture-card"
                          >
                            <div>
                              <PlusOutlined />
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                อย่างน้อย 2 รูป
                              </div>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col> */}
                    </>
                  ) : null}
                  <Col
                    span={24}
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <Form.Item>
                      <Button
                        style={{ width: isMediumUp ? 300 : 200 }}
                        type="primary"
                        htmlType="submit"
                      >
                        สมัครสมาชิก
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              ) : loadingIdcard ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Spin size="large" />
                  <p>กำลังโหลดข้อมูล</p>
                </div>
              ) : null}
            </Row>
          </Form>
          <p style={{ marginLeft: 32 }}>
            มีบัญชีผู้ใช้งานอยู่แล้ว? <Link to="/login">เข้าสู่ระบบ</Link>
          </p>
        </Card>
        <div
          style={{ marginTop: 50, justifyContent: "center", display: "flex" }}
        >
          <Link to="/products">กลับสู่หน้าหลัก</Link>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <p className="copyright">
            {" "}
            Copyright © 2023 by{" "}
            <a href="https://www.facebook.com/calleasing.kkn/">
              {" "}
              ONE LEASING.CO.,LTD
            </a>
            .{" "}
          </p>
        </div>
      </Container>
    </>
  );
}
