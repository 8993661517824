import React from "react";
// import { TextField, InputAdornment } from "@mui/material";
import { Select } from "antd";
// import carApi from "api/carApi";

export default function SearchBar({ data, onSelected }) {
  // const [data, setData] = useState([]);
  const handleChange = (v, e) => {
    onSelected(e);
  };

  // useEffect(() => {}, [data]);

  return (
    <>
      {/* <h5>ค้นหาจากประเภทรถ</h5> */}
      <Select
        defaultValue="ทั้งหมด"
        placeholder="ค้นหาจากประเภทรถ"
        size="large"
        style={{
          width: "100%",
          justifySelf: "flex-end",
        }}
        onChange={(v, e) => handleChange(v, e)}
      >
        <Select.Option key={0} value="ทั้งหมด" label={0} />;
        {data.map((el) => {
          return <Select.Option key={el.id} value={el.type_th} label={el.id} />;
        })}
      </Select>
    </>
  );
}
