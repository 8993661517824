import React, { useState, useEffect } from "react";
import { Select } from "antd";
import carApi from "api/carApi";

export default function SelectSubModel({
  typeId,
  modelId,
  lastPrice,
  onChange,
  value,
}) {
  //   const [brandId, setBrandId] = useState(brand);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    fetchData();
  }, [modelId]);

  const fetchData = async function () {
    const result = await carApi.get("/ratecars/model", {
      params: { typeId, modelId },
    });
    setData(result.data);
    if (result.data.length === 0) {
      fetchDataPriceHistoryNoSubmodel();
    }
  };

  const fetchDataPriceHistoryNoSubmodel = async () => {
    const result = await carApi.get("/salecar/admin/price-history", {
      params: { typeId, modelId },
    });
    lastPrice(result.data);
  };

  const fetchDataPriceHistoryWithSubmodel = async (e) => {
    const result = await carApi.get("/salecar/admin/price-history", {
      params: { typeId, modelId, submodelId: e },
    });
    lastPrice(result.data);
    // console.log(result.data);
  };

  const handleChange = (e, v) => {
    // console.log("TEST", v);
    onChange(v);
    fetchDataPriceHistoryWithSubmodel(e);
  };

  return (
    <Select value={value} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option
            key={item.idmodel}
            value={item.idmodel}
            name="sub_model_id"
          >
            {item.models}
          </Select.Option>
        );
      })}
    </Select>
  );
}
