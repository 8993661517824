import React, { useEffect, useState } from "react";
import { Divider, Modal, Radio, Form, Typography, Input, Select } from "antd";
import moment from "moment";

const role = [
  // {
  //   value: 1,
  //   label: "(1) ผู้ดูแลระบบ",
  //   disabled: true,
  // },
  {
    value: 2,
    label: "2. การตลาด (ขายปลีกเท่านั้น)",
  },
  {
    value: 3,
    label: "3. ผู้ร่วมประมูล (ประมูลเท่านั้น)",
  },
];

export default function AddUserModal({ open, onSubmit, onClose }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const data = {
      ...values,
    };
    Modal.confirm({
      title: "เพิ่มผู้ใช้งาน",
      content: "ยืนยันข้อมูลผู้ใช้งานหรือไม่?",
      centered: true,
      onOk: () => {
        onSubmit(data);
        form.resetFields();
        onClose(false);
      },
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose(false);
  };

  return (
    <Modal
      title="เพิ่มผู้ใช้งานใหม่"
      open={open}
      closeIcon={false}
      okText="บันทึก"
      cancelText="ยกเลิก"
      onCancel={handleCancel}
      onOk={form.submit}
      //   footer={[
      //     <Button key="back" onClick={handleCancel}>
      //       ยกเลิก
      //     </Button>,
      //     <Button
      //       //   disabled={disableSubmit}
      //       key="submit"
      //       type="primary"
      //       // loading={loading}
      //       onClick={handleSubmit}
      //     >
      //       ประมูล
      //     </Button>,
      //   ]}
    >
      <Divider />

      <Form
        form={form}
        onFinish={onFinish}
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        autoComplete="off"
        // onFinish={handleSubmit}
        // onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          label="ชื่อ"
          name="firstname"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="นามสกุล"
          name="lastname"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="เลขบัตร"
          name="idcard"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="ที่อยู่" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="รหัสไปรษณ์" name="zipcode">
          <Input />
        </Form.Item>
        <Form.Item
          label="โทรศัพท์"
          name="phone"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="อีเมล" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="ชื่อเล่น" name="nickname">
          <Input />
        </Form.Item>
        <Form.Item label="ชื่อเต็นท์รถ" name="car_tent_name">
          <Input />
        </Form.Item>
        <Form.Item label="ที่อยู่เต็นท์" name="car_tent_address">
          <Input />
        </Form.Item>
        <Form.Item label="เลขผู้เสียภาษี" name="car_tent_taxid">
          <Input />
        </Form.Item>
        <Form.Item label="โทรศัพท์" name="car_tent_phone">
          <Input />
        </Form.Item>
        <Form.Item label="หมายเหตุ" name="mark">
          <Input />
        </Form.Item>
        <Form.Item
          name="role_id"
          label="สิทธิ์การใช้งาน"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          initialValue={3}
        >
          <Select
            placeholder="สิทธิ์การใช้งาน"
            options={role.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
          />
          {/* {role.map((item) => (
            <Select.Option value={item.value}>{item.label}</Select.Option>
          ))} */}
        </Form.Item>
        <Form.Item
          label="สถานะ"
          name="status_id"
          rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          initialValue={1}
        >
          <Radio.Group style={{ width: 300 }} direction="vertical">
            <Radio value={1}>อนุมัติ</Radio>
            <Radio value={3}>ไม่อนุมัติ</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
