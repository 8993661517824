import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from "antd";
import carApi from "api/carApi";
import { addToken } from "components/store/tokenSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EditRetailStatus({ open, data, onClose, onSubmit }) {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);
  const [users, setUsers] = useState([]);
  const [defaultUser, setDefaultUser] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [soldDate, setSoldDate] = useState("");
  const [radioSelected, setRadioSelected] = useState(null);

  useEffect(() => {
    // console.log(data);
    form.resetFields();
    setResult(data);
    fetchAllUser();
    if (data.status_type_id === 1 || data.status_type_id === 2) {
      setRadioSelected(data?.status_type_id);
      setDisabled(true);
    } else {
      setRadioSelected(3);
      setDisabled(false);
    }

    return () => {
      setUsers([]);
      setResult(null);
      setDefaultUser({});
      setDisabled(false);
    };
  }, [data]);

  const fetchAllUser = async () => {
    await carApi
      .get("/salecar/admin/users/role/2", {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setUsers(data);
      })
      .catch((error) => {
        // setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleChange = (e) => {
    form.resetFields();
    setRadioSelected(e.target.value);
    if (e.target.value === 3) {
      setDisabled(false);
    } else {
      setSoldDate(null);
      setDisabled(true);
    }
    setResult({ ...result, status_type_id: e.target.value });
  };

  const hadleClickOk = () => {
    if (radioSelected === 3) {
      form.submit();
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    // console.log("Submit No Buyer", )
    const dataSubmit = {
      ...result,
      status_type_id: radioSelected,
      process_by_user_id: null,
      sold_out_date: null,
      sold_out_price: null,
      buyer_name: null,
    };

    // console.log("Submit No Buyer", dataSubmit);
    onSubmit(dataSubmit);
    handleClose();
  };

  const onFinish = (values) => {
    const dataSubmit = {
      ...result,
      ...values,
      status_type_id: 4,
      sold_out_date: soldDate,
    };
    // console.log("Submit With Buyer", dataSubmit);
    onSubmit(dataSubmit);
    handleClose();
  };

  const checkCurrentUser = () => {
    const current = users.find((user) => user.id === data.user_id);
    setDefaultUser(current);
    form.setFieldsValue({ process_by_user_id: current?.user_name });
  };

  const handelDateChange = (date, dateString) => {
    // console.log("handelDateChange", date, dateString);
    setSoldDate(dateString);
  };

  useEffect(() => {
    checkCurrentUser();
  }, [users]);

  if (result && open && users) {
    return (
      <Modal
        title={
          "สถานะสินค้า : " +
          result.brand +
          "  " +
          result.model +
          "  " +
          result.year
        }
        open={open}
        onOk={hadleClickOk}
        onCancel={handleClose}
      >
        <Divider />
        <Radio.Group
          direction="vertical"
          style={{ width: 500 }}
          defaultValue={
            data.status_type_id === 3 || data.status_type_id === 4
              ? 3
              : data.status_type_id
          }
          onChange={handleChange}
        >
          <Radio style={{ width: "100%" }} value={1}>
            คงอยู่
          </Radio>
          <Radio style={{ width: "100%" }} value={2}>
            รอดำเนินการ (อยู่ระหว่างซื้อขาย)
          </Radio>
          {/* <Radio style={{ width: "100%" }} value={3}>
            ขายแล้ว (แบบประมูล)
          </Radio> */}
          <Radio style={{ width: "100%" }} value={3}>
            ขายแล้ว (แบบขายปลีก)
          </Radio>
          <Divider />
          <Form
            disabled={disabled}
            form={form}
            onFinish={onFinish}
            name="basic"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 18,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="วันที่ขาย"
              name="date"
              rules={[
                {
                  required: true,
                  message: "เลือกวันที่ขาย",
                },
              ]}
            >
              <DatePicker onChange={handelDateChange} />
            </Form.Item>
            <Form.Item
              label="ผู้ขาย"
              name="process_by_user_id"
              rules={[
                {
                  required: true,
                  message: "เลือกผู้ขาย",
                },
              ]}
            >
              <Select>
                {users.map((user) => {
                  return (
                    <Select.Option key={user.id} value={user.id}>
                      {user.car_tent_name
                        ? user.car_tent_name
                        : user.firstname +
                          " " +
                          user.lastname +
                          " (" +
                          user.nickname +
                          ")"}{" "}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* {selectedUserRoleId === 2 && ( */}
            <Form.Item
              label="ผู้ซื้อ"
              name="buyer_name"
              rules={[
                {
                  required: true,
                  message: "ระบุชื่อผู้ซื้อ",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ราคาขาย"
              name="sold_out_price"
              initialValue={data?.sold_out_price}
              rules={[
                {
                  required: true,
                  message: "ระบุราคาขาย",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                // defaultValue={data?.retail_price}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Form.Item>
          </Form>
        </Radio.Group>
      </Modal>
    );
  }
}
