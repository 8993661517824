import { Spin } from "antd";
import React from "react";

export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Spin size="large" />
      <p>กำลังโหลดข้อมูล</p>
    </div>
  );
}
