import React, { useEffect, useState } from "react";
import { Divider, Modal, Radio, Form, Typography, Input, Select } from "antd";
import moment from "moment";

const role = [
  // {
  //   value: 1,
  //   label: "(1) ผู้ดูแลระบบ",
  //   disabled: true,
  // },
  {
    value: 2,
    label: "2. การตลาด (ขายปลีกเท่านั้น)",
  },
  {
    value: 3,
    label: "3. ผู้ร่วมประมูล (ประมูลเท่านั้น)",
  },
];

export default function EditUserModal({ open, data, onSubmit, onClose }) {
  const [form] = Form.useForm();
  //   const [value, setValue] = useState(2);
  //   const [date, setDate] = useState("");
  //   const [time, setTime] = useState("");
  const [result, setResult] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(false);
    if (data) {
      setResult(data);
    }
  }, [data]);

  const handleSubmit = (values) => {
    const data = {
      ...result,
      ...values,
    };
    onSubmit(data);
    form.resetFields();
    onClose(false);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose(false);
  };

  const CustomTextField = (text) => {
    return (
      <div
        style={{
          border: "1px solid rgba(128,128,128,0.3)",
          borderRadius: "6px",
          padding: 4,
        }}
      >
        <Typography style={{ marginLeft: 4, color: "gray" }}>{text}</Typography>
      </div>
    );
  };

  if (data) {
    return (
      <Modal
        title="แก้ไขข้อมูลผู้ใช้"
        open={open}
        closeIcon={false}
        okText="บันทึก"
        cancelText="ยกเลิก"
        onCancel={handleCancel}
        onOk={() => {
          handleSubmit(form.getFieldsValue());
        }}
        //   footer={[
        //     <Button key="back" onClick={handleCancel}>
        //       ยกเลิก
        //     </Button>,
        //     <Button
        //       //   disabled={disableSubmit}
        //       key="submit"
        //       type="primary"
        //       // loading={loading}
        //       onClick={handleSubmit}
        //     >
        //       ประมูล
        //     </Button>,
        //   ]}
      >
        <Divider />

        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          autoComplete="off"
          // onFinish={handleSubmit}
          // onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            label="ชื่อ"
            name="firstname"
            initialValue={data.firstname}
          >
            {CustomTextField(data.firstname)}
          </Form.Item>
          <Form.Item
            label="นามสกุล"
            name="lastname"
            initialValue={data.lastname}
          >
            {CustomTextField(data.lastname)}
          </Form.Item>
          <Form.Item
            label="วันเกิด"
            name="birthdate"
            initialValue={data.birthdate}
          >
            {CustomTextField(moment(data.birthdate).format("ll"))}
          </Form.Item>
          <Form.Item label="เลขบัตร" name="idcard" initialValue={data.idcard}>
            {CustomTextField(data.idcard)}
          </Form.Item>
          <Form.Item label="ที่อยู่" name="address" initialValue={data.address}>
            <Input />
          </Form.Item>
          <Form.Item
            label="รหัสไปรษณ์"
            name="zipcode"
            initialValue={data.zipcode}
          >
            <Input />
          </Form.Item>
          <Form.Item label="โทรศัพท์" name="phone" initialValue={data.phone}>
            <Input defaultValue={data.phone} />
          </Form.Item>

          <Form.Item label="อีเมล" name="email" initialValue={data.email}>
            <Input defaultValue={data.email} />
          </Form.Item>
          <Form.Item
            label="ชื่อเล่น"
            name="nickname"
            initialValue={data.nickname}
          >
            <Input defaultValue={data.email} />
          </Form.Item>
          <Form.Item
            label="ชื่อเต็นท์รถ"
            name="car_tent_name"
            initialValue={data.car_tent_name}
          >
            <Input defaultValue={data.phone} />
          </Form.Item>
          <Form.Item
            label="ที่อยู่เต็นท์"
            name="car_tent_address"
            initialValue={data.car_tent_address}
          >
            <Input defaultValue={data.email} />
          </Form.Item>
          <Form.Item
            label="เลขผู้เสียภาษี"
            name="car_tent_taxid"
            initialValue={data.car_tent_taxid}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="โทรศัพท์"
            name="car_tent_phone"
            initialValue={data.car_tent_phone}
          >
            <Input />
          </Form.Item>
          <Form.Item label="หมายเหตุ" name="mark" initialValue={data.mark}>
            <Input />
          </Form.Item>
          <Form.Item
            name="role_id"
            label="สิทธิ์การใช้งาน"
            initialValue={data.role_id}
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Select
              placeholder="สิทธิ์การใช้งาน"
              options={role.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
            ></Select>
          </Form.Item>
          <Form.Item
            label="สถานะ"
            name="status_id"
            initialValue={data.status_id}
          >
            <Radio.Group
              style={{ width: 300 }}
              direction="vertical"
              defaultChecked={data.status_id}
            >
              <Radio value={1}>อนุมัติ</Radio>
              <Radio value={3}>ไม่อนุมัติ</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
