import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
// import Dehaze from "@mui/icons-material/Dehaze";
import ProductItem from "./ProductItem";
import SearchBar from "./SearchBar";
// import { useNavigate } from "react-router-dom";
// import DrawerFilter from "./DrawerFilter";
import carApi from "api/carApi";
import Loading from "components/Loading";

export default function ProductList() {
  // const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productListHolders, setProductListHolders] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  // const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (productList.length === 0) {
      // console.log("TEST", productList);
      fetchAllProduct();
    }
  }, []);

  useEffect(() => {
    fetchVehicleTypes();
  }, []);

  const fetchVehicleTypes = async () => {
    setLoading(true);
    const { data } = await carApi.get(`/salecar/vehicle-types`);
    // console.log(data);
    setVehicleTypes(data);
    setLoading(false);
  };

  const fetchAllProduct = async () => {
    setLoading(true);
    const result = await carApi.get("/salecar/products", {
      params: { type: 1 },
    });
    const data = result.data;
    // console.log("Products", data);
    setProductList(data);
    setProductListHolders(data);
    setLoading(false);
  };

  const handleSearchVehicleType = (e) => {
    if (e.label === 0) {
      setProductList(productListHolders);
    } else {
      const newData = productListHolders.filter((item) => {
        return item.vehicle_type_id === e.label;
      });
      setProductList(newData);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <h3>ประมูลรถยนต์</h3>
        <SearchBar data={vehicleTypes} onSelected={handleSearchVehicleType} />
      </div>
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          variant="outlined"
          endIcon={<Dehaze />}
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          ตัวกรอง
        </Button>
      </div> */}

      <div style={{ marginTop: 36 }}>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Grid container spacing={2}>
            {productList.length > 0 ? (
              productList.map((item) => {
                // if (item.status_type_id === 1) {
                return <ProductItem key={item.id} data={item} />;
                // } else return null;
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography>ไม่พบข้อมูล</Typography>
              </div>
            )}
          </Grid>
        )}
      </div>
      {/* <DrawerFilter
        open={openDrawer}
        close={setOpenDrawer}
        brandList={setBrandList}
        priceRange={setPrice}
      /> */}
    </div>
  );
}
