import React, { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import { Modal, Button, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function OTPpassword({
  open,
  close,
  otpResult,
  onRequestOTP,
  onFailure,
}) {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [otp, setOtp] = useState("");

  useEffect(() => {
    setOtp("");
  }, [open, onFailure]);

  const handleOTPChange = (v) => {
    setOtp(v);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    otpResult(otp);
  };

  const clearOtp = () => {
    setOtp("");
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <Modal
      centered
      open={open}
      onClose={handleClose}
      footer={null}
      closable={false}
    >
      {/* <div className="card"> */}
      <form onSubmit={handleSubmit}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3>กรอกรหัส ที่ส่งไปยังเบอร์ของท่าน</h3>
          <OTPInput
            inputStyle={{
              width: isMediumUp ? "3rem" : "1.5rem",
              height: isMediumUp ? "3rem" : "1.5rem",
              margin: "20px 4px",
              fontSize: "1rem",
              borderRadius: 4,
              border: "2px solid rgba(0,0,0,0.3)",
            }}
            numInputs={6}
            onChange={handleOTPChange}
            value={otp}
            inputType="text"
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
          />
          <div>
            <Button
              style={{ margin: 8 }}
              disabled={otp.trim() === ""}
              onClick={clearOtp}
            >
              ล้างข้อมูล
            </Button>
            <Button
              type="primary"
              style={{ margin: 8 }}
              disabled={otp.length < 6}
              onClick={handleSubmit}
            >
              ยืนยันรหัส
            </Button>
          </div>
          <div
            style={{
              marginTop: 16,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {onFailure ? (
              <p style={{ color: "red", fontWeight: "bold" }}>
                รหัสยืนยันไม่ถูกต้อง!!
              </p>
            ) : null}
            <p>หากไม่ได้รับรหัสภายใน 3 นาที</p>
            <Space
              // split={6}
              direction="vertical"
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button onClick={onRequestOTP} icon={<ReloadOutlined />}>
                ขอรหัสอีกครั้ง
              </Button>
              <Button onClick={handleClose}>ยกเลิก</Button>
            </Space>
          </div>
        </div>
      </form>
    </Modal>
  );
}
