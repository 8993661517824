import React, { useEffect } from "react";
import { Container, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Routers from "./Routers";

export default function Content() {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <main style={{ padding: 16 }}>
      {isMediumUp ? (
        <Container>
          <Toolbar />
          <Routers />
        </Container>
      ) : (
        <div>
          <Toolbar />
          <Routers />
        </div>
      )}
    </main>
  );
}
