export const removeZeroValueObj = (item) =>
  Object.keys(item)
    .filter((key) => item[key] !== 0 && item[key] !== null)
    .reduce((newObj, key) => {
      newObj[key] = item[key];
      return newObj;
    }, {});

export const trimAllValueObj = (item) => {
  // trim all values object
  Object.keys(item).map(
    (e) => (item[e] = typeof item[e] == "string" ? item[e].trim() : item[e])
  );
  return item;
};

export const currencyFormat = (amount) => {
  return new Intl.NumberFormat().format(amount);
};

export const currencyFormatTH = (amount) => {
  return new Intl.NumberFormat("th-TH", {
    style: "currency",
    currency: "THB",
  }).format(amount);

  // return Number(amount)
  // .toFixed(2)
  // .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
