import React from "react";
import { Routes, Route } from "react-router-dom";
import UserList from "./UserList";
import UserDetail from "./UserDetail";
import AuctionCompleteListWithUser from "./AuctionCompleteListWithUser";

export default function Routers() {
  return (
    <Routes>
      <Route path="/admin/list" element={<UserList />} />
      {/* {/* <Route path="/:id" element={<ProductDetail />} /> */}
      <Route path="/:id" element={<UserDetail />} />
      <Route
        path="/auction-list/:id"
        element={<AuctionCompleteListWithUser />}
      />
    </Routes>
  );
}
