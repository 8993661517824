import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import "moment/locale/th";

const KSM_SEAL =
  "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCJfEE9lhxN0exCTSPeUwlzxnjuVgH4UEY8DfX7Kh4vqLH65SuSdf1kbVYUa-lJoYJZolVaE1oiw9UPP-M2RMfZdoLF1Q=s1600";

const KSM_WATERMARK =
  "https://lh3.googleusercontent.com/drive-viewer/AK7aPaCZIAzR1s7DYp0FCJk4L4g1TjcDU1pDB7gKqZBelBJZUypG8zIwfi0UrmkVAJ0lFyWi_h72MRUbyxYM7WXsH_1cMUbohQ=s1600";

const KSM_LOGO =
  "https://lh3.googleusercontent.com/drive-viewer/AK7aPaDhoGILu3ZkKByuLLlCOdLGdAMf1A2DY5WPVtBMn9xz5jw60nQttaLQfls33yxvOSEBeO8ryp9mF1t_LWAFVazo9uMakw=s1600";

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

export const offerPricePDF = async ({ rec }) => {
  var docDefinition = {
    pageSize: "A4",
    // [left, top, right, bottom]
    pageMargins: [30, 30, 30, 30],
    background: [
      {
        image: await getBase64ImageFromURL(KSM_WATERMARK),
        width: 500,
        alignment: "center",
        margin: [0, 150, 0, 0],
      },
    ],
    content: [
      {
        columns: [
          {
            image: await getBase64ImageFromURL(KSM_LOGO),
            width: 170,
            height: 120,
          },
          {
            stack: [
              {
                text: `บริษัท เค.เอส.เอ็ม. บิลเลี่ยนแนร์ จำกัด`,
                style: "textBody",
                alignment: "right",
                fontSize: 16,
                bold: true,
              },
              {
                text: `\r\n279 หมู่ 12 ถนนมิตรภาพ ตำบลเมืองเก่า อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000\r\nโทรศัพท์ 043-306822\r\nเลขที่ผู้เสียภาษี 0-4055-47000-97-0 (สำนักงานใหญ่)`,
                style: "textBody",
                alignment: "right",
                fontSize: 14,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        text: `ใบเสนอราคา`,
        style: "textBody",
        alignment: "center",
        fontSize: 26,
        bold: true,
      },
      // {

      // stack: [

      {
        alignment: "right",
        text: [
          {
            text: `วันที่พิมพ์   `,
            style: "textBody",
            bold: true,
            fontSize: 14,
          },
          {
            text: ` ${moment(new Date()).format("LL")}`,
            style: "textBody",
            // alignment: "right",
            fontSize: 14,
          },
        ],
      },

      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                margin: [10, 10, 10, 10],
                stack: [
                  {
                    text: [
                      {
                        text: `ชื่อลูกค้า `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${
                          rec.user_nickname
                            ? rec.user_name +
                              " " +
                              rec.user_lastname +
                              " (" +
                              rec.user_nickname +
                              ")"
                            : rec.user_name + " " + rec.user_lastname
                        }`,
                        style: "textBody",
                        // alignment: "right",
                        fontSize: 14,
                      },
                      {
                        text: `   ที่อยู่ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${rec.user_address + " " + rec.user_zipcode}`,
                        style: "textBody",
                        // alignment: "right",
                        fontSize: 14,
                      },
                      {
                        text: `   โทรศัพท์ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${rec.user_phone}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        text: `ชื่อเต็นท์รถ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${rec.user_car_tent_name || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                      {
                        text: `   ที่อยู่ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${rec.user_car_tent_address || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                      {
                        text: `   โทรศัพท์ `,
                        style: "textBody",
                        bold: true,
                        fontSize: 14,
                      },
                      {
                        text: ` ${rec.user_car_tent_phone || "-"}`,
                        style: "textBody",
                        fontSize: 14,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },

      // ],
      // },
      {
        margin: [0, 10, 0, 10],
        table: {
          headerRows: 1,
          heights: [20, 220, 70],
          widths: [100, 300, "*"],
          body: [
            [
              {
                text: "รหัสสินค้า",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },
              {
                text: "รายละเอียดสินค้า",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },
              {
                text: "ราคา",
                alignment: "center",
                bold: true,
                fontSize: 14,
              },
            ],
            [
              {
                margin: [0, 10, 0, 0],
                text: ` ${rec.product_code}`,
                fontSize: 14,
                // style: "textBody",
                // alignment: "right",
                // fontSize: 16,
              },
              {
                margin: [0, 10, 0, 0],
                stack: [
                  {
                    fontSize: 14,
                    text: `${rec.brand}  ${rec.model}  (${rec.year})  สี ${rec.color} `,
                  },

                  {
                    text: `${rec.gear_type}  เลขไมล์ ${rec.miles}`,
                    fontSize: 14,
                  },
                  {
                    text: `เลขทะเบียน ${rec.register_no}`,
                    fontSize: 14,
                  },
                  {
                    text: `เลขคัตซี ${rec.chassis_no}`,
                    fontSize: 14,
                  },
                ],
              },
              {
                margin: [0, 10, 0, 0],
                alignment: "right",
                text: `${currencyFormat(rec.auction_total)}  บาท`,
                fontSize: 14,
              },
            ],
            [
              {
                border: [true, false, false, true],
                text: `หมายเหตุ`,
                fontSize: 14,
                bold: true,
              },
              {
                border: [false, false, false, true],
                text: `รวมเป็นเงิน`,
                fontSize: 14,
                alignment: "right",
                bold: true,
              },
              {
                // border: [true, false, false, true],
                text: `${currencyFormat(rec.auction_total)}  บาท`,
                fontSize: 14,
                alignment: "right",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        margin: [0, 70, 0, 0],
        columns: [
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `${rec.user_name}  ${rec.user_lastname}`,
              //   fontSize: 14,
              // },
              {
                text: `ผู้ซื้อ`,
                fontSize: 14,

                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: `วันที่............................................`,
                fontSize: 14,
              },
            ],
          },
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `ศุภมิตร แก้วสุโพธิ์`,
              //   fontSize: 14,
              // },
              {
                text: `ฝ่ายขาย`,
                fontSize: 14,
                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: `วันที่............................................`,
                fontSize: 14,
              },
            ],
          },
          {
            alignment: "center",
            stack: [
              {
                margin: [0, 0, 0, 10],
                text: `\t\t\t\t\t\t\t\t\t\t\t`,
                decoration: "underline",
                decorationStyle: "dotted",
                // fontSize: 14,
              },
              // {
              //   text: `รัมภา อรุณเดชาชัย`,
              //   fontSize: 14,
              // },
              {
                text: `ผู้อนุมัติขาย`,
                fontSize: 14,
                bold: true,
              },
              {
                margin: [0, 10, 0, 0],
                text: `วันที่............................................`,
                fontSize: 14,
              },
            ],
          },
        ],
      },
    ],
    defaultStyle: { font: "THSarabunNew" },
    styles: {
      withMargin: {
        margin: [20, 20, 20, 20],
      },
      alignCenter: {
        alignment: "center",
      },
      header: {
        fontSize: 18,
        bold: true,
      },
      textBody: {
        fontSize: 16,
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
    },
  };
  return docDefinition;
};
