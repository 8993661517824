import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Card, Divider, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { currencyFormatTH } from "components/utils/Utils";
import ExportExcel from "./ExportExcel";

const { Text } = Typography;

export default function ReportSales({ data, title }) {
  const [auctionList, setAuctionList] = useState([]);
  const [retailList, setRetailList] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    retail: 0,
    auction: 0,
  });

  useEffect(() => {
    if (data.length > 0) {
      const retail = data.filter((item) => item.total_retail_price !== "0");
      const auction = data.filter((item) => item.total_auction_total !== "0");
      const totalRetail = retail.reduce(
        (sum, item) => sum + parseInt(item.total_retail_price),
        0
      );
      const totalAuction = auction.reduce(
        (sum, item) => sum + parseInt(item.total_auction_total),
        0
      );

      console.log("auction", auction);

      setRetailList(retail);
      setAuctionList(auction);
      setTotalPrice({ retail: totalRetail, auction: totalAuction });
    }
  }, [data]);

  const renderItem = (item) => {
    return (
      <div>
        {item.status_type_id === 3 ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to={"/users/" + item.id}>
              {item.car_tent_name
                ? item.car_tent_name
                : item.firstname +
                  " " +
                  item.lastname +
                  " " +
                  "(" +
                  item.nickname +
                  ")"}
            </Link>
            <Text>{currencyFormatTH(item.total_auction_total)}</Text>
          </div>
        ) : (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to={"/users/" + item.id}>
              {item.firstname} {item.lastname} ({item.nickname})
            </Link>
            <Text>{currencyFormatTH(item.total_retail_price)}</Text>
          </div>
        )}
      </div>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      <ExportExcel title={title} auction={auctionList} retail={retailList} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card title="ยอดขายประมูล">
              {auctionList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.auction)}</Text>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card title="ยอดขายปลีก">
              {retailList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.retail)}</Text>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
