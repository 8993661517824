import React, { useEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Space,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import EChart from "./EChart";
import carApi from "api/carApi";
import ReportSales from "./ReportSales";
import ExportExcel from "./ExportExcel";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function ReportMain() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eChart, setEchart] = useState([]);
  const [saleList, setSaleList] = useState([]);
  const currentDate = moment().format("YYYY-MM-DD");
  const [titleExcel, setTitleExcel] = useState("");

  useEffect(() => {
    let params = {
      dateStart: moment().add(-7, "months").format("YYYY-MM-DD"),
      dateEnd: currentDate,
    };
    setTitleExcel({
      dateStart: moment(params.dateStart).format("MM-YYYY"),
      dateEnd: moment(params.dateEnd).format("MM-YYYY"),
    });
    fetchSumPriceWeek(params);
    fetchAllSaleList(params);
  }, []);

  const fetchSumPriceWeek = async (params) => {
    console.log(params);
    const result = await carApi.get("/salecar/report/monthly", {
      params,
      // headers: {
      //   "x-access-token": user.token,
      // },
    });
    console.log(result.data);
    setEchart(result.data);
  };

  const fetchAllSaleList = async (params) => {
    const result = await carApi.get("/salecar/report/sales-list", {
      params,
      // headers: {
      //   "x-access-token": user.token,
      // },
    });
    console.log("Sales", result.data);
    setSaleList(result.data);
  };

  const handleChangeDate = (date, dateString) => {
    console.log(date, dateString);
    if (dateString[0] !== "" && dateString[1] !== "") {
      let params = {
        dateStart: dateString[0] + "-30",
        dateEnd: dateString[1] + "-30",
      };

      setTitleExcel({
        dateStart: moment(params.dateStart).format("MM-YYYY"),
        dateEnd: moment(params.dateEnd).format("MM-YYYY"),
      });
      fetchSumPriceWeek(params);
      fetchAllSaleList(params);
    }

    // let url = "";
  };

  return (
    <div>
      <h3>สรุปรายงานการขาย</h3>

      {/* <ExportExcel data={saleList} /> */}

      <Space direction="vertical" style={{ width: "100%" }}>
        <EChart data={eChart} />
        <Text strong>เลือกเดือนที่แสดงแผนภูมิ</Text>
        <RangePicker
          style={{ width: "100%", marginTop: "16px" }}
          picker="month"
          onChange={handleChangeDate}
        />
      </Space>
      <Divider />
      <ReportSales data={saleList} title={titleExcel} />
    </div>
  );
}
