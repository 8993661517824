import { Select } from "antd";
import carApi from "api/carApi";
import React, { useEffect, useState } from "react";

export default function SelectProductType({ onChange, value }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, v) => {
    // console.log(e, v);
    onChange(v);
  };

  const fetchData = async function () {
    const result = await carApi.get("/salecar/product-types");
    setData(result.data);
  };
  return (
    <Select value={value || 1} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id} name="product_type_id">
            {item.type_th}
          </Select.Option>
        );
      })}
    </Select>
  );
}
