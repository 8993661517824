import React, { useState, useEffect } from "react";
import { Select } from "antd";
import carApi from "api/carApi";

export default function SelectModel({ brandId, onChange, value }) {
  //   const [brandId, setBrandId] = useState(brand);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    fetchData();
  }, [brandId]);

  const handleChange = (e, v) => {
    onChange(v);
  };

  const fetchData = async function () {
    const result = await carApi.get("/ratecars/model_te/" + brandId);
    setData(result.data);
  };
  return (
    <Select value={value} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option
            key={item.idmodel_te}
            value={item.idmodel_te}
            name="model_te_id"
          >
            {item.model}
          </Select.Option>
        );
      })}
    </Select>
  );
}
