import { Grid, useMediaQuery } from "@mui/material";
import carApi from "api/carApi";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Descriptions,
  Divider,
  Image,
  Card,
  Modal,
  Button,
  Space,
  Tag,
} from "antd";
import AuctionCompleteListWithUser from "./AuctionCompleteListWithUser";
import Loading from "components/Loading";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import EditUserModal from "./EditUserModal";
import SoldItemListWithUser from "./SoldItemListWithUser";
export default function UserDetail() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    loadUserProfile();
  }, [id]);

  const loadUserProfile = async () => {
    setLoading(true);
    await carApi
      .get(`/salecar/users/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);

        // setImageList([{ url: response.data.image_path }]);
        //   if (response.data.product_code) {
        //     loadImagesProduct(response.data.product_code);
        //     setImageShow(response.data.image_path);
        //   }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleEditData = () => {
    setOpenEdit(true);
  };

  const handleSubmit = (value) => {
    updateUserDetails(value);
  };

  const updateUserDetails = async (body) => {
    setLoading(true);
    await carApi
      .put("/salecar/users", body, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        Modal.success({ content: "อัพเดตข้อมูลสำเร็จ" });
        loadUserProfile();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h3>รายละเอียดสมาชิก</h3>

      <Card>
        <Grid
          container
          spacing={8}
          justifyContent="center"
          justify={isMediumUp ? "flex-start" : "center"}
        >
          <Grid item>
            {/* <Grid item>
              <Image
                // style={{ borderRadius: "50%" }}
                //   preview={false}
                width={isMediumUp ? 400 : 250}
                src="https://static.thairath.co.th/media/dFQROr7oWzulq5Fa4Vt7gRszwrmpShVNVGk6avOyLEqnilxfNd8dD4KycLRnmwf9bQc.webp"
              />
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Divider /> */}
        <Grid item>
          <Descriptions title="ข้อมูลส่วนตัว">
            <Descriptions.Item label="ชื่อ">{data.firstname}</Descriptions.Item>
            <Descriptions.Item label="นามสกุล">
              {data.lastname}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อเล่น">
              {data.nickname}
            </Descriptions.Item>
            <Descriptions.Item label="วันเกิด">
              {moment(data.birthdate).format("ll")} (
              {moment().year() - moment(data.birthdate).year()} ปี)
            </Descriptions.Item>
            <Descriptions.Item label="เลขประจำตัวประชาชน">
              {data.idcard}
            </Descriptions.Item>
            <Descriptions.Item label="ที่อยู่">
              {data.address}
            </Descriptions.Item>
            <Descriptions.Item label="โทรศัพท์">{data.phone}</Descriptions.Item>
            <Descriptions.Item label="อีเมล์">
              {data.email || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="หมายเหตุ">{data.mark}</Descriptions.Item>
          </Descriptions>
        </Grid>
        <Divider />
        {user.role !== "seller" && (
          <>
            <Grid item>
              <Descriptions title="ข้อมูลเต็นท์รถ">
                <Descriptions.Item label="ชื่อเต็นท์">
                  {data.car_tent_name || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="ที่อยู่">
                  {data.car_tent_address || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="เลขผู้เสียภาษี">
                  {data.car_tent_taxid || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="โทรศัพท์">
                  {data.car_tent_phone || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="ความสนใจพิเศษ">
                  {data.interest || "-"}
                </Descriptions.Item>
              </Descriptions>
            </Grid>
            <Divider />
          </>
        )}
        <div
          style={{
            // width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Space direction="row" align="center">
            <p style={{ color: "gray" }}>สถานะ</p>
            <Tag
              color={
                data.status_id === 1
                  ? "success"
                  : data.status_id === 2
                  ? "processing"
                  : "error"
              }
              style={{ padding: 8, fontSize: 14 }}
            >
              {data.status}
            </Tag>
          </Space>
          <h4 style={{ color: "blue" }}>
            สิทธิ์การใช้งาน:{" "}
            {data.role === "admin"
              ? "admin"
              : data.role === "seller"
              ? "การตลาด"
              : "ผู้ร่วมประมูล"}
          </h4>
        </div>
        {user.role === "admin" && (
          <>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                // flexDirection: "column",
              }}
            >
              <Button type="primary" onClick={handleEditData}>
                แก้ไขข้อมูล หรือ สถานะ
              </Button>
            </div>
          </>
        )}
      </Card>

      <Divider />

      {data.role !== "seller" ? (
        <>
          <h3>รายการประมูลของฉัน</h3>
          <AuctionCompleteListWithUser id={id} />
        </>
      ) : (
        <>
          <h3>รายการขายของฉัน</h3>
          <SoldItemListWithUser id={id} />
        </>
      )}
      <EditUserModal
        open={openEdit}
        onClose={setOpenEdit}
        data={data}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
