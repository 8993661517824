import React, { useEffect, useState } from "react";
import carApi from "api/carApi";
import {
  Button,
  Image,
  Space,
  Table,
  Tag,
  Typography,
  Statistic,
  Divider,
  Modal,
} from "antd";

import moment from "moment";
import { currencyFormat } from "components/utils/Utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";

const { Countdown } = Statistic;

export default function SoldItemListWithUser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [retailCarList, setRetailCarList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    LoadRetailCarList();
  }, []);

  const LoadRetailCarList = async () => {
    setLoading(true);
    await carApi
      .get(`/salecar/users/${id}/retails/`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setRetailCarList(response.data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // Modal.error({ title: "ผิดพลาด", content: err.message, centered: true });
      });
  };

  const columns = [
    {
      key: 1,
      dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return <Image width={200} src={rec} />;
      },
    },
    {
      key: 2,
      title: "รายละเอียดสินค้า",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Link to={"/retails/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Typography style={{ color: "gray" }}>
              วันที่ลง {moment(rec.date).format("ll")}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand}
            </Typography>
            <Typography>{rec.model}</Typography>
            <Typography></Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ {currencyFormat(rec.miles)} กม.
            </Typography>
            <Typography style={{ fontWeight: "bold", color: "green" }}>
              ราคาขาย {currencyFormat(rec.retail_price)}
            </Typography>
          </div>
        );
      },
    },
    // {
    //   key: 2,
    //   title: "รายการขาย",
    //   render: (rec) => {},
    // },
    {
      key: 4,
      //   dataIndex: "status_type_id",
      title: "สถานะสินค้า",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";

            break;
          case 2:
            color = "processing";

            break;
          case 3:
            color = "error";

            break;

          default:
            break;
        }

        return (
          <Space direction="vertical">
            <Tag color={color}>{rec.status}</Tag>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>รายการรถที่ประมูล</h3>
        <Button onClick={handleAuctionListClick} type="primary">
          รายการที่ประมูลทั้งหมด
        </Button>
      </div> */}
      <Table dataSource={retailCarList} columns={columns}></Table>
      {/* <AuctionProductListModal
        open={openAuctionListModal}
        data={auctionList}
        onClose={setOpenAuctionListModal}
      /> */}
    </div>
  );
}
