import {
  Divider,
  Form,
  Typography,
  Modal,
  Button,
  Select,
  InputNumber,
  Card,
} from "antd";
import carApi from "api/carApi";
import { addToken } from "components/store/tokenSlice";
import { currencyFormatTH } from "components/utils/Utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export default function CalculateLoan({ open, onClose, data, onSubmit }) {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loanDetail, setLoanDetail] = useState({
    vatPerMonth: 1.25,
    vatPerYear: 15,
    vat: 7,
  });

  useEffect(() => {
    // console.log("DATA", Math.ceil(6977.33));
    console.log("RESULT", loanDetail);
  }, [loanDetail]);

  const handleClose = () => {
    setLoanDetail({
      vatPerMonth: 1.25,
      vatPerYear: 15,
      vat: 7,
    });
    form.resetFields();
    onClose(false);
  };

  const handleSubmit = () => {
    onSubmit(data);
  };

  const handleFinishForm = (values) => {
    console.log("Values", values);
    const balancePrice = ~~values.retail_price - ~~values.retail_down_price;
    // console.log("balancePrice", balancePrice);

    const balancePriceWithWarrantyPrice =
      balancePrice + ~~values.retail_loan_warranty;

    const balanceWithVatPerMonth = (balancePriceWithWarrantyPrice / 100) * 1.25;
    console.log("balanceWithVatPerMonth", balanceWithVatPerMonth);

    const balanceWithVatPerYear = (balancePriceWithWarrantyPrice / 100) * 15;

    const AmountWithVatPermount =
      balanceWithVatPerMonth * ~~values.retail_loan_count;
    console.log("AmountWithVatPermount", AmountWithVatPermount);

    const totalLoanAmount =
      balancePriceWithWarrantyPrice + AmountWithVatPermount;

    const installmentAmount = totalLoanAmount / ~~values.retail_loan_count;

    const vat7Permonth = (installmentAmount / 100) * 7;

    const installmentAmountWithVat7Permonth = installmentAmount + vat7Permonth; // ปัดเศษ
    console.log(
      "installmentAmountWithVat7Permonth",
      installmentAmountWithVat7Permonth
    );

    setLoanDetail({
      ...loanDetail,
      price: ~~values.retail_price,
      downPrice: ~~values.retail_down_price,
      WarrantyPrice: ~~values.retail_loan_warranty,
      loanCount: ~~values.retail_loan_count,
      balancePrice: balancePrice,
      balancePriceWithWarrantyPrice: balancePriceWithWarrantyPrice,
      balanceWithVatPerYear: balanceWithVatPerYear,
      AmountWithVatPermount: AmountWithVatPermount,
      totalLoanAmount: totalLoanAmount,
      installmentAmount: installmentAmount,
      vat7Permonth: vat7Permonth,
      installmentAmountWithVat7Permonth: Math.ceil(
        installmentAmountWithVat7Permonth
      ),
    });
  };

  const rederitem = (title, value, color) => {
    return (
      <div
        style={{
          paddingRight: 4,
          paddingLeft: 4,
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          backgroundColor: color,
        }}
      >
        <Text type="secondary">{title}</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  const renderCalculate = (data) => {
    return (
      <Card
        style={{
          display: "flex",
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        {rederitem("ยอดคงเหลือ", currencyFormatTH(data.balancePrice))}
        {rederitem(
          "รวม ป 2 + หรือ ป 3+",
          currencyFormatTH(data.WarrantyPrice),
          "#F5F5F5"
        )}
        {rederitem(
          "ยอดคงเหลือรวมประกัน",
          currencyFormatTH(data.balancePriceWithWarrantyPrice)
        )}
        {rederitem(
          `ดอกเบี้ย ${data.vatPerYear}% ต่อปี`,
          currencyFormatTH(data.balanceWithVatPerYear),
          "#F5F5F5"
        )}
        {rederitem(
          `ดอกเบี้ยผ่อน ${data.loanCount} เดือน`,
          currencyFormatTH(data.AmountWithVatPermount)
        )}
        {rederitem(
          `ยอดรวมดอกเบี้ย`,
          currencyFormatTH(data.totalLoanAmount),
          "#F5F5F5"
        )}
        {rederitem(`ค่างวดต่อเดือน`, currencyFormatTH(data.installmentAmount))}
        {rederitem(
          `รวม vat ${data.vat}%`,
          currencyFormatTH(data.vat7Permonth),
          "#F5F5F5"
        )}
        {rederitem(
          `รวมค่างวดต่อเดือน`,
          currencyFormatTH(data.installmentAmountWithVat7Permonth)
        )}
      </Card>
    );
  };

  return (
    <Modal
      title={"คำนวณยอดผ่อน"}
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
      //   closable={false}
      footer={[
        <Button
          // type="primary"
          // htmlType="submit"
          onClick={handleClose}
        >
          ปิด
        </Button>,
      ]}
    >
      <Divider />

      <Form
        //   disabled={disabled}
        onFinish={handleFinishForm}
        form={form}
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        autoComplete="off"
      >
        <Form.Item
          label="ราคาขาย"
          name="retail_price"
          initialValue={data.retail_price}
        >
          <InputNumber
            style={{ width: "100%" }}
            defaultValue={data.retail_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>
        <Form.Item
          label="เงินดาวน์"
          name="retail_down_price"
          initialValue={data.retail_down_price}
        >
          <InputNumber
            style={{ width: "100%" }}
            defaultValue={data.retail_down_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            // onChange={(e) =>
            //   setLoanDetail({
            //     ...loadDetail,
            //     downPrice: ~~e.target.value,
            //   })
            // }
          />
        </Form.Item>
        <Form.Item label="ค่าประกัน" name="retail_loan_warranty">
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            // onChange={(e) =>
            //   setLoanDetail({
            //     ...loadDetail,
            //     WarrantyPrice: ~~e.target.value,
            //   })
            // }
          />
        </Form.Item>
        <Form.Item
          label="จำนวนงวด"
          name="retail_loan_count"
          rules={[
            {
              required: true,
              message: "กำหนดงวดผ่อนชำระ",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // onClick={handleSubmit}
          >
            คำนวณ
          </Button>
        </Form.Item>
      </Form>
      {loanDetail.installmentAmountWithVat7Permonth &&
        renderCalculate(loanDetail)}
    </Modal>
  );
}
