import React from "react";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Button } from "antd";
import moment from "moment";
import { currencyFormat } from "components/utils/Utils";

export default function ExportExcel({ title, auction, retail }) {
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet1 = workbook.addWorksheet("รายการขายประมูล");

    worksheet1.columns = [
      { header: "วันที่ซื้อล่าสุด", key: "last_sold_out_date", width: 20 },
      { header: "ชื่อเต๊นท์รถ", key: "car_tent_name", width: 20 },
      { header: "เบอร์โทรเต๊นท์รถ", key: "car_tent_phone", width: 15 },
      { header: "ชื่อ", key: "firstname", width: 15 },
      { header: "สกุล", key: "lastname", width: 15 },
      { header: "ชื่อเล่น", key: "nickname", width: 15 },
      { header: "โทรศัพท์", key: "phone", width: 15 },
      {
        header: "ยอดขายประมูล",
        key: "total_auction_total",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
    ];

    worksheet1.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCFFCC" },
      };
      cell.font = { bold: true };
    });

    auction.forEach((item, index) => {
      const row = worksheet1.addRow({
        //   id: item.id,
        car_tent_name: item.car_tent_name || "-",
        car_tent_phone: item.car_tent_phone || "-",
        firstname: item.firstname,
        lastname: item.lastname,
        nickname: item.nickname || "-",
        phone: item.phone,
        total_auction_total: currencyFormat(item.total_auction_total),
        last_sold_out_date: moment(item.last_sold_out_date).format("LL"),
      });

      //   if (index % 2 === 0) {
      //     row.eachCell((cell) => {
      //       cell.fill = {
      //         type: "pattern",
      //         pattern: "solid",
      //         fgColor: { argb: "FFFFE0B2" }, // สีส้มอ่อน
      //       };
      //     });
      //   }
    });

    const totalSum1 = auction.reduce(
      (sum, item) => sum + Number(item.total_auction_total),
      0
    );

    const totalRow1 = worksheet1.addRow({
      id: "",
      car_tent_name: "",
      car_tent_phone: "",
      firstname: "",
      lastname: "",
      nickname: "ยอดขายรวม",
      phone: "",
      total_auction_total: currencyFormat(totalSum1),
      last_sold_out_date: "",
    });

    const lastRow1 = worksheet1.lastRow;
    lastRow1.getCell("nickname").font = { bold: true };
    lastRow1.getCell("total_auction_total").font = { bold: true };

    totalRow1.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFB3E5FC" },
      };
    });

    ////////////////////////////////////////////// SHEET 2 //////////////////////////////////////////////

    const worksheet2 = workbook.addWorksheet("รายการขายปลีก");
    worksheet2.columns = [
      { header: "วันที่ซื้อล่าสุด", key: "last_sold_out_date", width: 20 },
      { header: "ชื่อเต๊นท์รถ", key: "car_tent_name", width: 20 },
      { header: "เบอร์โทรเต๊นท์รถ", key: "car_tent_phone", width: 15 },
      { header: "ชื่อ", key: "firstname", width: 15 },
      { header: "สกุล", key: "lastname", width: 15 },
      { header: "ชื่อเล่น", key: "nickname", width: 15 },
      { header: "โทรศัพท์", key: "phone", width: 15 },
      {
        header: "ยอดขายปลีก",
        key: "total_retail_price",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
    ];

    worksheet2.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCFFCC" },
      };
      cell.font = { bold: true };
    });

    retail.forEach((item, index) => {
      const row = worksheet2.addRow({
        //   id: item.id,
        car_tent_name: item.car_tent_name || "-",
        car_tent_phone: item.car_tent_phone || "-",
        firstname: item.firstname,
        lastname: item.lastname,
        nickname: item.nickname || "-",
        phone: item.phone,
        total_retail_price: currencyFormat(item.total_retail_price),
        last_sold_out_date: moment(item.last_sold_out_date).format("LL"),
      });

      //   if (index % 2 === 0) {
      //     row.eachCell((cell) => {
      //       cell.fill = {
      //         type: "pattern",
      //         pattern: "solid",
      //         fgColor: { argb: "FFFFE0B2" }, // สีส้มอ่อน
      //       };
      //     });
      //   }
    });

    const totalSum2 = retail.reduce(
      (sum, item) => sum + Number(item.total_retail_price),
      0
    );

    const totalRow2 = worksheet2.addRow({
      id: "",
      car_tent_name: "",
      car_tent_phone: "",
      firstname: "",
      lastname: "",
      nickname: "ยอดขายรวม",
      phone: "",
      total_retail_price: currencyFormat(totalSum2),
      last_sold_out_date: "",
    });

    const lastRow2 = worksheet2.lastRow;
    lastRow2.getCell("nickname").font = { bold: true };
    lastRow2.getCell("total_retail_price").font = { bold: true };

    totalRow2.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFB3E5FC" },
      };
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `เดือน ${title.dateStart} ถึง ${title.dateEnd} .xlsx`);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 24,
      }}
    >
      <Button type="primary" onClick={exportToExcel}>
        ดาวน์โหลดรายงาน
      </Button>
    </div>
  );
}
