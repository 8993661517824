import React, { useEffect, useState } from "react";
import {
  Divider,
  Modal,
  Radio,
  DatePicker,
  TimePicker,
  InputNumber,
  Form,
  Button,
} from "antd";
// import carApi from "api/carApi";

export default function PreviewProductModal({ open, data, onSubmit, onClose }) {
  const [form] = Form.useForm();
  const [value, setValue] = useState(2);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  // const [bit, setBit] = useState(5000);
  const [result, setResult] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(false);
    if (data) {
      setResult(data);
    }
  }, [data]);

  useEffect(() => {
    if (date && time) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [date, time]);

  const handleSubmit = () => {
    onSubmit(result);
    form.resetFields();
    setDate("");
    setTime("");
    onClose(false);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose(false);
  };

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
    setDisableSubmit(true);
    if (e.target.value === 2) {
      setResult({
        ...result,
        auction_due_datetime: null,
        auction_bitrate: 0,
        auction_total: 0,
        auction_total_update_datetime: null,
        process_by_user_id: null,
      });
      setDisableSubmit(false);
    }
  };

  const onChangeNumber = (e) => {
    setResult({ ...result, auction_bitrate: e });
  };

  const onDateChange = (date, dateString) => {
    setDate(dateString);
    setResult({ ...result, auction_due_datetime: dateString });
  };

  const onTimeChange = (time, timeString) => {
    setTime(timeString);
    const dateTime = result.auction_due_datetime;
    setResult({
      ...result,
      auction_due_datetime: dateTime + "T" + timeString + ":00.000Z",
    });
  };

  return (
    <Modal
      title="จัดการ การประมูล"
      open={open}
      closeIcon={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          ยกเลิก
        </Button>,
        <Button
          disabled={disableSubmit}
          key="submit"
          type="primary"
          // loading={loading}
          onClick={handleSubmit}
        >
          ประมูล
        </Button>,
      ]}
    >
      <Divider />
      <Radio.Group
        style={{ width: 300 }}
        direction="vertical"
        onChange={onChange}
      >
        <Radio value={1}>เริ่มการประมูล</Radio>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 16,
          }}
          autoComplete="off"
        >
          <Form.Item
            label="วันที่สิ้นสุด"
            name="date"
            rules={[
              {
                required: true,
                message: "กำหนดวันที่สิ้นสุด",
              },
            ]}
          >
            <DatePicker
              onChange={onDateChange}
              disabled={value === 1 ? false : true}
            />
          </Form.Item>
          <Form.Item
            label="เวลาสิ้นสุด"
            name="time"
            rules={[
              {
                required: true,
                message: "กำหนดเวลาสิ้นสุด",
              },
            ]}
          >
            <TimePicker
              onChange={onTimeChange}
              disabled={date ? false : true}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item
            label="บิทขั้นต่ำ"
            name="bit"
            rules={[
              {
                required: true,
                message: "กรุณาระบุบิทขั้นต่ำ",
              },
            ]}
          >
            <InputNumber
              disabled={value === 1 ? false : true}
              defaultValue={0}
              onChange={onChangeNumber}
            />{" "}
            บาท
          </Form.Item>
        </Form>

        <Radio value={2}>ยกเลิกการประมูล</Radio>
      </Radio.Group>
    </Modal>
  );
}
