import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Upload,
  Row,
  Col,
  Space,
  Card,
  Modal,
  Typography,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import Resizer from "react-image-file-resizer";
import { PlusOutlined } from "@ant-design/icons";
import SelectBrand from "../add_products/SelectBrand";
import SelectModel from "../add_products/SelectModel";
import SelectSubModel from "../add_products/SelectSubModel";
import SelectGearType from "../add_products/SelectGearType";
import SelectFuelType from "../add_products/SelectFuelType";
import SelectRegistrationType from "../add_products/SelectRegistrationType";
import moment from "moment";
import carApi from "api/carApi";
import SelectVehicleType from "../add_products/SelectVehicleType";
import SelectProductType from "../add_products/SelectProductType";

const { TextArea, Search } = Input;

export default function EditProductDetailModal({
  open,
  onClose,
  data,
  onSubmit,
  onDelete,
}) {
  const [form] = Form.useForm();
  const [isChassis, setIsChassis] = useState(false);
  const [productData, setProductData] = useState({});
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    console.log("DATA", data);
    form.resetFields();
    setProductData(data);
    form.setFieldsValue({
      ...data,
      // auction_due_datetime: dayjs(data.auction_due_datetime),
      registration_date: dayjs(data.registration_date),
      tax_expiration_date: dayjs(data.tax_expiration_date),
    });

    return () => {
      // setProductData(null);
    };
  }, [data]);

  // useEffect(() => {
  //   console.log("defaultCheckList", defaultCheckList);
  // }, [defaultCheckList]);

  const handleChangeSelect = (e) => {
    console.log(e.value);
    setProductData({ ...productData, [e.name]: e.value });
  };

  const handleChangeInput = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleChangeRadio = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleChangeDate = (e) => {
    setProductData({ ...productData, [e.name]: e.value });
  };

  const handleSubmit = () => {
    onSubmit(productData, fileList);
    onClose(false);
  };

  const onSearchChassisNo = (e) => {
    checkChassisNo(e);
  };

  const checkChassisNo = async (e) => {
    // console.log("CheckChassisNo", e);
    const result = await carApi.get("/salecar/chassis-number/" + e);
    // console.log(result.data);
    if (result.data) {
      setIsChassis(true);
    } else {
      setIsChassis(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "ลบรายการสินค้านี้",
      // icon: <ExclamationCircleFilled />,
      content: "คุณต้องการลบรายการสินค้านี้หรือไม่?",
      okText: "ลบ",
      okType: "danger",
      centered: true,
      cancelText: "ยกเลิก",
      onOk() {
        onDelete(productData.id);
        onClose(false);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const options = [
    {
      label: "รถประมูล",
      value: 1,
    },
    {
      label: "ขายปลีก",
      value: 2,
    },
  ];

  const handleChangeCheckBox = (checkedValues) => {
    // console.log("checked = ", checkedValues);
    if (checkedValues[0] === 1 && checkedValues[1] === 2)
      setProductData({ ...productData, auction: true, retail: true });
    else if (checkedValues[0] === 1)
      setProductData({ ...productData, auction: true, retail: false });
    else if (checkedValues[0] === 2)
      setProductData({ ...productData, auction: false, retail: true });
    else setProductData({ ...productData, auction: false, retail: false });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        768,
        "JPEG",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  let arr = [];
  const propsMultifile = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const image = await resizeFile(file);
      // console.log("FILE", image);
      arr.push(image);
      setFileList([...fileList, ...arr]);
      return false;
    },
    // fileList,
  };

  return (
    <Modal
      title="แก้ไขสินค้า"
      // width={1000}
      destroyOnClose
      open={open}
      onOk={handleSubmit}
      closeIcon={false}
      // onCancel={handleClose}
      footer={[
        <Button key="delete" danger onClick={handleDelete}>
          ลบสินค้านี้
        </Button>,
        <Button
          key="close"
          // href="https://google.com"
          // loading={loading}
          onClick={handleClose}
        >
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          บันทึก
        </Button>,
      ]}
    >
      <Card>
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 18,
          }}
          layout="horizontal"
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="sale_type_id"
            label="ประเภทการขาย"
            initialValue={
              data.auction && data.retail
                ? [1, 2]
                : data.auction
                ? [1]
                : data.retail
                ? [2]
                : []
            }
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Checkbox.Group options={options} onChange={handleChangeCheckBox} />
          </Form.Item>
          <Form.Item
            name="contract_no"
            label="เลขสัญญาเดิม"
            // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="contract_no" onChange={handleChangeInput} />
          </Form.Item>
          <Form.Item
            name="register_no"
            label="เลขทะเบียน"
            // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="register_no" onChange={handleChangeInput} />
          </Form.Item>

          <Form.Item name="product_type_id" label="ประเภทสินค้า">
            <SelectProductType onChange={handleChangeSelect} />
          </Form.Item>
          <Form.Item
            name="vehicle_type_id"
            label="ชนิดรถ"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectVehicleType
              value={data.vehicle_type_id}
              onChange={handleChangeSelect}
            />
          </Form.Item>

          <Form.Item
            name="brand_id"
            label="ยี่ห้อรถ"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectBrand
              value={data.brand_id}
              typeId={productData.vehicle_type_id}
              onChange={handleChangeSelect}
            />
          </Form.Item>
          <Form.Item
            name="model_te_id"
            label="รุ่นรถ"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectModel
              value={data.model_te_id}
              brandId={productData.brand_id}
              onChange={handleChangeSelect}
            />
          </Form.Item>
          <Form.Item
            name="sub_model_id"
            label="รุ่นย่อย"
            // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectSubModel
              value={data.sub_model_id}
              typeId={productData.vehicle_type_id}
              modelId={productData.model_te_id}
              onChange={handleChangeSelect}
            />
          </Form.Item>

          <Form.Item
            name="year"
            label="ปีรถ"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="year" onChange={handleChangeInput} />
          </Form.Item>
          <Form.Item
            name="chassis_no"
            label="เลขคัตซี"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            extra={
              isChassis ? (
                <p style={{ color: "red" }}>เลขคัตซีนี้เคยถูกลงขายแล้ว</p>
              ) : (
                <p style={{ color: "green" }}>ยังไม่มีข้อมูลเลขคัตซี</p>
              )
            }
          >
            <Search
              name="chassis_no"
              onChange={handleChangeInput}
              allowClear
              onSearch={onSearchChassisNo}
            />
          </Form.Item>
          {/* </Col>
            <Col span={isMediumUp ? 12 : 24}> */}
          <Form.Item
            name="color"
            label="สี"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="color" onChange={handleChangeInput} />
          </Form.Item>
          <Form.Item
            name="miles"
            label="เลขไมล์ (กิโลเมตร)"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="miles" onChange={handleChangeInput} />
          </Form.Item>
          <Form.Item
            name="gear_type_id"
            label="ชนิดเกียร์"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectGearType onChange={handleChangeSelect} />
          </Form.Item>
          <Form.Item
            name="fuel_type_id"
            label="เชื้อเพลิง"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectFuelType onChange={handleChangeSelect} />
          </Form.Item>
          <Form.Item
            name="seats"
            label="จำนวนที่นั่ง"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Input name="seats" onChange={handleChangeInput} />
          </Form.Item>
          <Form.Item
            name="registration_type_id"
            label="ประเภทรถจดทะเบียน"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <SelectRegistrationType onChange={handleChangeSelect} />
          </Form.Item>
          {productData.auction && (
            <Form.Item
              name="auction_price"
              label={<h3 style={{ color: "red" }}>ราคาเปิดประมูล</h3>}
              rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            >
              <Input name="auction_price" onChange={handleChangeInput} />
            </Form.Item>
          )}
          {productData.retail && (
            <>
              <Form.Item
                name="retail_price"
                label={<h3 style={{ color: "red" }}>ราคาขายปลีก</h3>}
                rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
              >
                <Input name="retail_price" onChange={handleChangeInput} />
              </Form.Item>
              <Form.Item
                name="retail_down_price"
                label={<h3 style={{ color: "red" }}>เงินดาวน์</h3>}
                rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
              >
                <Input name="retail_down_price" onChange={handleChangeInput} />
              </Form.Item>
              <Form.Item
                name="promotion"
                label={<h4 style={{ color: "green" }}>โปรโมชั่น</h4>}
                // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
              >
                <TextArea
                  name="promotion"
                  rows={4}
                  onChange={handleChangeInput}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="mark"
            label="หมายเหตุ"
            // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <TextArea name="mark" rows={4} onChange={handleChangeInput} />
          </Form.Item>
          {/* </Col>
            <Col span={isMediumUp ? 12 : 24}> */}
          <Form.Item
            name="registration_date"
            label="วันที่จดทะเบียน"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <DatePicker
              defaultValue={moment(productData.registration_date)}
              format="YYYY-MM-DD"
              name="registration_date"
              onChange={(date, dateString) =>
                handleChangeDate({
                  name: "registration_date",
                  value: dateString,
                })
              }
            />
          </Form.Item>

          <Form.Item
            name="spare_key"
            label="กุญแจสำรอง"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Radio.Group name="spare_key" onChange={handleChangeRadio}>
              <Radio value={1}>มี</Radio>
              <Radio value={0}>ไม่มี</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="main_warranty"
            label="การรับประกันหลัก"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Radio.Group name="main_warranty" onChange={handleChangeRadio}>
              <Radio value={1}>มี</Radio>
              <Radio value={0}>ไม่มี</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="service_book_center"
            label="สมุดคู่มือการเข้าศูนย์บริการ"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <Radio.Group
              name="service_book_center"
              onChange={handleChangeRadio}
            >
              <Radio value={1}>มี</Radio>
              <Radio value={0}>ไม่มี</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="tax_expiration_date"
            label="วันหมดอายุภาษี"
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            <DatePicker
              name="tax_expiration_date"
              onChange={(date, dateString) =>
                handleChangeDate({
                  name: "tax_expiration_date",
                  value: dateString,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="เพิ่มรูปภาพ"
            name="all_image"
            // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            // valuePropName="fileList"
            // getValueFromEvent={normFile}
          >
            <Upload
              {...propsMultifile}
              // directory
              //  action="/upload.do"
              listType="picture-card"
              multiple
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  อัพโหลด
                </div>
              </div>
            </Upload>
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  );
}
