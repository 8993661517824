import { Image, Modal, Table, Typography } from "antd";
import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function AuctionProductListModal({ open, data, onClose }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    // console.log("DATA", data);
    setList(data);
  }, [data]);

  const handleClose = () => {
    onClose(false);
  };

  const columns = [
    {
      key: 1,
      align: "center",
      // dataIndex: "ชื่อผู้มใ",
      title: "รายละเอียดรถ",
      render: (rec) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Link to={"/products/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Image width={150} src={rec.image_path} />
            <Typography>
              {rec.brand} {rec.model} ({rec.year})
            </Typography>
          </div>
        );
      },
    },
    {
      key: 2,
      width: 120,
      dataIndex: "log_time_stamp",
      title: "เวลาประมูล",
      render: (rec) => {
        return moment(rec).format("lll");
      },
    },
    {
      key: 3,
      // width: 100,
      dataIndex: "log_price",
      title: "เสนอราคา",
      render: (rec) => {
        return (
          <Typography style={{ color: "green", fontWeight: "bold" }}>
            {currencyFormat(rec)}
          </Typography>
        );
      },
    },
  ];

  //   const renderList = () => {
  //     return (
  //       <Space>
  //         <Typography>เวลา</Typography>
  //         <Typography>ผู้ประมูล</Typography>
  //         <Typography>ยอดประมูล</Typography>
  //       </Space>
  //     );
  //   };

  return (
    <Modal
      title="รายการรถที่ทำการประมูล"
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <Table dataSource={list} columns={columns} />
    </Modal>
  );
}
