import React, { useState, useEffect } from "react";
import { Select } from "antd";
import carApi from "api/carApi";

export default function SelectFuelType({ onChange, value }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, v) => {
    // console.log(e);
    onChange(v);
  };

  const fetchData = async function () {
    const result = await carApi.get("/salecar/fuel-type");
    setData(result.data);
  };
  return (
    <Select value={value} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id} name="fuel_type_id">
            {item.type_th}
          </Select.Option>
        );
      })}
    </Select>
  );
}
