import React, { useState, useEffect } from "react";
import { Select } from "antd";
import carApi from "api/carApi";

export default function SelectRegistrationType({ onChange, value }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, v) => {
    // console.log(e, v);
    onChange(v);
  };

  const fetchData = async function () {
    const result = await carApi.get("/salecar/registration-type");
    setData(result.data);
  };
  return (
    <Select value={value} onChange={(e, v) => handleChange(e, v)}>
      {data.map((item) => {
        return (
          <Select.Option
            key={item.id}
            value={item.id}
            name="registration_type_id"
          >
            {item.type_th}
          </Select.Option>
        );
      })}
    </Select>
  );
}
