import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import { useSelector } from "react-redux";
import TableRetailList from "./table_retail_list/TableRetailList";
import CarDetail from "./dashboard/CarDetail";

export default function Routers() {
  const user = useSelector((state) => state.token);
  // console.log("ROUTE", user);
  return (
    <Routes>
      <Route path="/:id" element={<CarDetail />} />
      <Route path="/" element={<Dashboard />} />
      {user.role === "admin" ? (
        <>
          {/* <Route path="/admin/add" element={<AddProducts />} /> */}
          <Route path="/admin/list" element={<TableRetailList />} />
          {/* <Route
            path="/admin/actions-completed-list"
            element={<AuctionCompletedList />}
          /> */}
        </>
      ) : null}
    </Routes>
  );
}
